<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<style>
body {
  background: #ffffff !important;
}
.logo_login {
  width: 450px;
}

.brand {
  background-color: #1e2758 !important;
}

/* .header {
  background-color: #1E2758 !important;
} */

.aside {
  background-color: #1e2758 !important;
}

.aside-menu {
  background-color: #1e2758 !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #2541b2 !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #2541b2 !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: #f9f9f9 !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
  color: #f9f9f9 !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-arrow {
  color: #f9f9f9 !important;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-arrow {
  color: #f9f9f9 !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-link
  .menu-arrow {
  color: #f9f9f9 !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:hover
  > .menu-link
  .menu-arrow {
  color: #f9f9f9 !important;
}

.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  font-size: 16px !important;
  color: #f9f9f9 !important;
}

.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
  font-size: 16px !important;
  color: #f9f9f9 !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-link
  .menu-icon {
  font-size: 16px !important;
  color: #f9f9f9 !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-link
  .menu-text {
  font-size: 16px !important;
  color: #f9f9f9 !important;
}

.v-toolbar__content {
  padding: 0 !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 15px !important;
  color: #1e2758 !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead {
  background-color: #e9effd;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 14px !important;
}

.v-text-field > .v-input__control > .v-input__slot {
  /* background: transparent !important; */
  box-shadow: none !important;
}

.form-password > .v-text-field > .v-input__control > .v-input__slot {
  /* background: #f4f6fb !important; */
  border-radius: 10px;
  border: 2px solid #3532c4;
  padding: 6px 12px;
}

.form-input-login > .v-text-field > .v-input__control > .v-input__slot {
  /* background: #f4f6fb !important; */
  border-radius: 10px;
  border: 2px solid #3532c4;
  padding: 6px 12px;
}

.form-input-login
  > .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  background: #ffffff;
}

.form-input-login > .v-select__slot {
  background: #ffffff;
}

th .v-data-table__checkbox .v-input--selection-controls__input i {
  color: #ffffff;
}

.theme--light.v-btn {
  color: #5a5454 !important;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #1e2758 !important;
}

.v-text-field fieldset,
.v-text-field .v-input__control {
  color: #1e2758 !important;
}

.v-application .primary--text {
  color: #1e2758 !important;
  caret-color: #1e2758 !important;
}

.theme--light.v-card {
  background-color: #f4f6fb !important;
}

.btn.btn-outline-secondary {
  border-color: #6c757d !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none !important;
}

.theme--light.v-text-field:not(.v-input--has-state):hover
  > .v-input__control
  > .v-input__slot:before {
  border: none !important;
  border-color: #ffffff !important;
}

.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  color: #ffffff !important;
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  padding: 15px 0 !important;
  border-radius: inherit !important;
}

.theme--light.v-input input {
  padding: 0 12px !important;
}

.v-text-field fieldset,
.v-text-field .v-input__control {
  color: #f3f6f9 !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: #f3f6f9 !important;
}

/* .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: #ffffff !important;
} */

.theme--light.v-toolbar.v-sheet {
  background-color: transparent !important;
}

@media only screen and (max-width: 820px) {
  .logo_login {
    width: 400px;
  }
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 20px !important;
}

.container {
    max-width: inherit !important;
    padding: 25px !important;
}
</style>

<style lang="sass">
#medicalRecordHome
  $record-text-size:14px

  .record-item-value
    font-size: $record-text-size
    border-bottom: 1px solid #a1a1a1
  .record-item-unit
    font-size: $record-text-size
  .record-item-value-border
    border: 1px solid #a1a1a1
    margin-right: 4px

  .record-item-key
    font-weight: bold
    font-size: $record-text-size

  .record-content
    border: 1px solid #a1a1a1
  .v-data-table
    td
      padding: 12px 8px
      font-size: $record-text-size
      border-color: rgba(0,0,0,0.12)
      border-style: solid
      border-left-width: 0px
      border-right-width: 1px
      border-top-width: 0px
      border-bottom-width: 1px
    th
      border-color: rgba(0,0,0,0.12)
      border-style: solid
      border-left-width: 0px
      border-right-width: 1px
      border-top-width: 0px
      border-bottom-width: 1px
  .v-data-table table
    border: solid 1px #a1a1a1
    border-radius: 5px
  .v-data-table table thead tr th
    font-weight: bold
    font-size: $record-text-size
    padding: 0px 8px

  .v-data-table table tbody tr td .v-btn
    margin-right: 0px !important

  .v-data-table .v-data-table-header__sort-badge
    font-size: 10px

  .v-data-table.theme--dark
    tr th
      color: #fff !important

  .theme--light
    .v-data-table table thead tr th
      color: black
    .v-data-table table thead tr td
      color: black
</style>

<script>
import Vue from "vue";
// import money from "v-money";
// Vue.use(money, { precision: 4 });

import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
Vue.prototype.$totalJumlah = 0;
Vue.prototype.temp = 0;
export default {
  name: "MetronicVue",
  // methods: {
  //   Notification() {
  //     Vue.prototype.$escrow_notification = 11;
  //   }
  // },
  Notification() {
    Vue.prototype.$escrow_notification = 11;
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  }
};
</script>
