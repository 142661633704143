import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import Services from "@/core/services/app_internal/Services";
import localStorage from "./localStorage";
// import { resolve } from "core-js/fn/promise";
// import { axios } from "axios";
import Swal from "sweetalert2";
// import escrowNotification from "@/view/layout/extras/offcanvas/QuickUser.vue";
// import Vue from "vue";
// import App from "@/App";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const NOTIFICATION = "notification";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(credentials.email)
      ) {
        var mydata = {
          Trigger: "R",
          Token: "adasdasdas",
          Route: "LOGIN",
          UID: "asaas",
          user_email: credentials.email,
          user_password: credentials.password
        };

        let contentType = `application/x-www-form-urlencoded`;
        
        const date = new Date;
        const time = date.getMonth()+1 + date.getDate() + date.getHours() + date.getMinutes();
        const qs = require("qs");
        
        let ctr = 1;
        let date_attempt = date;

        if(localStorage.getLocalStorage('_CTR_')){
          ctr = parseInt(localStorage.getLocalStorage('_CTR_')) + 1;
        }

        if(localStorage.getLocalStorage('_CTRD_')){
          date_attempt = new Date(localStorage.getLocalStorage('_CTRD_'));
        }

        localStorage.setLocalStorage("_CTR_", ctr);   
        let calc_time_attempt = (date - date_attempt) / (1000 * 60);

        Services.PostData(
          ApiService,
          "Master/Users",
          qs.stringify(mydata),
          contentType,
          response => {
            if(ctr <= 3 || (ctr > 3 && calc_time_attempt >= 5)){
              if(calc_time_attempt >= 5){
                ctr = 1;
              }
              localStorage.setLocalStorage("_CTR_", ctr);
              resolve(response.data);
              if (response.data.length > 0) {
                context.commit(SET_AUTH, response.data);
                localStorage.setLocalStorage("UID", response.data[0].user_id);
                localStorage.setLocalStorage(
                  "User_Email",
                  response.data[0].user_email
                );
                localStorage.setLocalStorage(
                  "User_Fullname",
                  response.data[0].user_fullname
                );
                localStorage.setLocalStorage(
                  "User_Role",
                  response.data[0].division_name
                );
              } else {
                context.commit(SET_ERROR, []);
                localStorage.setLocalStorage("_CTRD_", date);
                Swal.fire({
                  title: "",
                  text: "Gagal Login",
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary",
                  heightAuto: true,
                  timer: 1500
                });
              }
            }else{              
              context.commit(SET_ERROR, []);
                Swal.fire({
                  title: "",
                  text: "Mohon Tunggu 5 Menit",
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary",
                  heightAuto: true,
                  timer: 1500
              });
            }
          },
          err => {
            context.commit(SET_ERROR, err);
          }
        );
      } else {
        Swal.fire({
          title: "",
          text: "Masukkan email dan password dengan benar.",
          icon: "info",
          heightAuto: true,
          timer: 1500
        });
      }
    });

    // return new Promise(resolve => {
    //   ApiService.post("login", credentials)
    //     .then(({ data }) => {
    //       context.commit(SET_AUTH, data);
    //       resolve(data);
    //     })
    //     .catch(({ response }) => {
    //       context.commit(SET_ERROR, response.data.errors);
    //     });
    // });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      let email = "";
      let password = "";

      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(credentials.email)
      ) {
        email = credentials.email;
        password = credentials.password;
      }

      var mydata = {
        Trigger: "C",
        Token: "C",
        Route: "DEFAULT",
        UID: "C",
        user_fullname: credentials.user_fullname,
        user_email: email,
        division_id: credentials.division_id,
        user_password: password,
        status: 1
      };

      let contentType = `application/x-www-form-urlencoded`;

      const qs = require("qs");

      Services.PostData(
        ApiService,
        "Master/Users",
        qs.stringify(mydata),
        contentType,
        response => {
          resolve(response.data);
          if (response.status == 1000) {
            Swal.fire({
              title: "",
              text: "Berhasil Daftar",
              icon: "success",
              heightAuto: true,
              timer: 1500
            });
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "info",
              heightAuto: true,
              timer: 1500
            });
          }
        },
        err => {
          err;
        }
      );
    });

    // return new Promise(resolve => {
    //   ApiService.post("login", credentials)
    //     .then(({ data }) => {
    //       context.commit(SET_AUTH, data);
    //       resolve(data);
    //     })
    //     .catch(({ response }) => {
    //       context.commit(SET_ERROR, response.data.errors);
    //     });
    // });
  },
  [NOTIFICATION]() {
    return new Promise(resolve => {
      var mydata = {
        Trigger: "R",
        Token: "R",
        UID: localStorage.getLocalStorage("UID"),
        Route: "ESCROW_PAYMENT_ACCEPTED",
        payment_accepted: "E",
        status_receipt: 1,
        status_escrow_accepted: 0
      };

      let contentType = `application/x-www-form-urlencoded`;

      const qs = require("qs");

      Services.PostData(
        ApiService,
        "Transaction/BankTransaction",
        qs.stringify(mydata),
        contentType,
        response => {
          resolve(response.data);
          this.data_escrow_notification = response.data;
          // escrowNotification.methods.escrowNotification(response.data.length);
          localStorage.setLocalStorage(
            "escrow_notification_value",
            response.data.length
          );
          // console.log(response.data.length);
        },
        err => {
          err;
        }
      );
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      // ApiService.setHeader();
      // ApiService.get("verify")
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data.errors);
      //   });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
