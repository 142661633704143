import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        // router to dashboard
        {
          path: "",
          name: "",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "/dashboard",
              name: "dashboard",
              component: () => import("@/view/pages/dashboard/Dashboard.vue")
            }
          ]
        },
        // router to master-activities
        {
          path: "/master/activities",
          name: "master-activities",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/view/pages/master/Master_Activities.vue")
            }
          ]
        },
        // router to master-workgroup
        {
          path: "/master/workgroup",
          name: "master-workgroup",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/view/pages/master/Master_Workgroup.vue")
            }
          ]
        },
        // router to master-division
        {
          path: "/master/division",
          name: "master-division",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/view/pages/master/Master_Division.vue")
            }
          ]
        },
        // router to master-organizational-units
        {
          path: "/master/organizational-units",
          name: "master-organizational-units",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/view/pages/master/Master_Organizational_Units.vue")
            }
          ]
        },
        // router to master-work-units
        {
          path: "/master/work-units",
          name: "master-work-units",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/view/pages/master/Master_Work_Units.vue")
            }
          ]
        },
        // router to master-work-units
        {
          path: "/master/sub-work-units",
          name: "master-sub-work-units",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/view/pages/master/Master_Sub_Work_Units.vue")
            }
          ]
        },
        // router to master-part-of-sub-work-units
        {
          path: "/master/part-of-sub-work-units",
          name: "master-part-of-sub-work-units",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/view/pages/master/Master_Part_Of_Sub_Work_Units.vue")
            }
          ]
        },
        // router to master > positions
        {
          path: "/master/positions",
          name: "master-positions",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/view/pages/master/Master_Positions.vue")
            }
          ]
        },
        // router to master-bank
        {
          path: "/master/bank",
          name: "master-bank",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/view/pages/master/Master_Bank.vue")
            }
          ]
        },
        // router to master-bank-account
        {
          path: "/master/bank-account",
          name: "master-bank-account",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/view/pages/master/Master_Bank_Account.vue")
            }
          ]
        },
        // router to master-project
        {
          path: "/master/project",
          name: "master-project",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/view/pages/master/Master_Project.vue")
            }
          ]
        },
        // router to master-exchange-rate
        {
          path: "/master/exchange-rate",
          name: "master-exchange-rate",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/view/pages/master/Master_Exchange_Rate.vue")
            }
          ]
        },
        // router to master-financings
        {
          path: "/master/financings",
          name: "master-financings",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/view/pages/master/Master_Financings.vue")
            }
          ]
        },
        // router to master-cheque
        {
          path: "/master/cheque",
          name: "master-cheque",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/view/pages/master/Master_Cheque.vue")
            }
          ]
        },
        // router to master-currency
        {
          path: "/master/currency",
          name: "master-currency",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/view/pages/master/Master_Currency.vue")
            }
          ]
        },
        // router to large cashbank -> cashbank in
        {
          path: "/cashbank/large-cashbank/cashbank-in",
          name: "cashbank-in",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/large-cashbank/cashbank-in/Cashbank_In.vue"
                )
            }
          ]
        },
        // router to large cashbank -> cashbank out
        {
          path: "",
          name: "cashbank-out",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            // internal
            {
              path: "/cashbank/large-cashbank/cashbank-out/internal/voucher",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/large-cashbank/cashbank-out/internal/Voucher.vue"
                )
            },
            // operational submission form
            {
              path:
                "/cashbank/large-cashbank/cashbank-out/operational/submission-form",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/large-cashbank/cashbank-out/operational/submission-form/Submission_Form.vue"
                )
            },
            // operational voucher
            {
              path: "/cashbank/large-cashbank/cashbank-out/operational/voucher",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/large-cashbank/cashbank-out/operational/voucher/Voucher.vue"
                )
            }
          ]
        },
        // router to large cashbank -> transaction reports
        {
          path: "/cashbank/large-cashbank/transaction-reports",
          name: "transaction-reports",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/large-cashbank/transaction-reports/Transaction_Reports.vue"
                )
            }
          ]
        },
        // router to small cashbank -> cashbank in
        {
          path: "",
          name: "cashbank-in",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "/cashbank/small-cashbank/cashbank-in/voucher",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/small-cashbank/cashbank-in/Cashbank_In.vue"
                )
            }
          ]
        },
         // router to small cashbank from notif -> cashbank in
         {
          path: "",
          name: "",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "/cashbank/small-cashbank/cashbank-in/voucher",
              name: "shortcut-cashbank-in",
              component: () =>
                import(
                  "@/view/pages/cashbank/small-cashbank/cashbank-in/Cashbank_In.vue"
                ),
                props: true
            },
            {
              path: "/cashbank/small-cashbank/cashbank-out/internal/voucher",
              name: "shortcut-cashbank-out",
              component: () =>
                import(
                  "@/view/pages/cashbank/small-cashbank/cashbank-out/internal/Voucher.vue"
                ),
                props: true
            },
            {
              path: "/cashbank/small-cashbank/cashbank-out/operational/voucher",
              name: "shortcut-cashbank-out-ops",
              component: () =>
                import(
                  "@/view/pages/cashbank/small-cashbank/cashbank-out/operational/voucher/Voucher.vue"
                ),
                props: true
            },
            {
              path: "/cashbank/small-cashbank/cashbank-out/admin-project/voucher",
              name: "shortcut-cashbank-out-adm",
              component: () =>
                import(
                  "@/view/pages/cashbank/small-cashbank/cashbank-out/admin-project/voucher/Voucher.vue"
                ),
                props: true
            },
            {
              path: "/bank-guarantee/internal/submission-form/finance",
              name: "shortcut-bgoi",
              component: () =>
                import(
                  "@/view/pages/bank-guarantee/internal/submission-form/finance/Submission_Form.vue"
                ),
                props: true
            },
            {
              path: "/bank-guarantee/external/internal/voucher",
              name: "shortcut-bgoe",
              component: () =>
                import(
                  "@/view/pages/bank-guarantee/external/internal/Internal.vue"
                ),
                props: true
            },
            {
              path: "/bank-guarantee/external/external/voucher",
              name: "shortcut-bgoe-masuk",
              component: () =>
                import(
                  "@/view/pages/bank-guarantee/external/external/External.vue"
                ),
                props: true
            },
          ]
        },
        // router to small cashbank -> cashbank out
        {
          path: "",
          name: "cashbank-out",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            // internal
            {
              path: "/cashbank/small-cashbank/cashbank-out/internal/voucher",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/small-cashbank/cashbank-out/internal/Voucher.vue"
                )
            },
            // operational submission form
            {
              path:
                "/cashbank/small-cashbank/cashbank-out/operational/submission-form",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/small-cashbank/cashbank-out/operational/submission-form/Submission_Form.vue"
                )
            },
            // operational voucher
            {
              path: "/cashbank/small-cashbank/cashbank-out/operational/voucher",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/small-cashbank/cashbank-out/operational/voucher/Voucher.vue"
                )
            },
            // admin project
            {
              path:
                "/cashbank/small-cashbank/cashbank-out/admin-project/submission-form",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/small-cashbank/cashbank-out/admin-project/submission-form/Submission_Form.vue"
                )
            },
            // admin project voucher
            {
              path:
                "/cashbank/small-cashbank/cashbank-out/admin-project/voucher",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/small-cashbank/cashbank-out/admin-project/voucher/Voucher.vue"
                )
            }
          ]
        },
        // router to small cashbank -> transaction reports
        {
          path: "/cashbank/small-cashbank/transaction-reports",
          name: "transaction-reports",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/view/pages/cashbank/small-cashbank/transaction-reports/Transaction_Reports.vue"
                )
            }
          ]
        },
        // router to bank in
        {
          path: "",
          name: "",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "/bank-in-out/bank-in",
              name: "bank-in",
              component: () =>
                import("@/view/pages/bank-in-out/bank-in/Bank_In.vue"),
              props: true
            }
          ]
        },
        // router to bank out
        {
          path: "",
          name: "",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "/bank-in-out/bank-out",
              name: "bank-out",
              component: () =>
                import("@/view/pages/bank-in-out/bank-out/Bank_Out.vue")
            }
          ]
        },
        // router to bank-guarantee
        {
          path: "",
          name: "",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "/bank-guarantee/internal/submission-form/operational",
              name: "operational",
              component: () =>
                import(
                  "@/view/pages/bank-guarantee/internal/submission-form/operational/Submission_Form.vue"
                )
            },
            {
              path: "/bank-guarantee/internal/submission-form/finance",
              name: "finance",
              component: () =>
                import(
                  "@/view/pages/bank-guarantee/internal/submission-form/finance/Submission_Form.vue"
                )
            },
            {
              path:
                "/bank-guarantee/external/internal/submission-form/operational",
              name: "internal-operational",
              component: () =>
                import(
                  "@/view/pages/bank-guarantee/external/internal/submission-form/operational/Submission_Form.vue"
                )
            },
            {
              path: "/bank-guarantee/external/internal/voucher",
              name: "internal",
              component: () =>
                import(
                  "@/view/pages/bank-guarantee/external/internal/Internal.vue"
                )
            },
            {
              path:
                "/bank-guarantee/external/external/submission-form/operational",
              name: "internal-operational",
              component: () =>
                import(
                  "@/view/pages/bank-guarantee/external/external/submission-form/operational/Submission_Form.vue"
                )
            },
            {
              path: "/bank-guarantee/external/external/voucher",
              name: "internal",
              component: () =>
                import(
                  "@/view/pages/bank-guarantee/external/external/External.vue"
                )
            },
            {
              path: "/bank-guarantee/external/external",
              name: "external",
              component: () =>
                import(
                  "@/view/pages/bank-guarantee/external/external/External.vue"
                )
            },
            {
              path: "/bank-guarantee/guarantee/guarantee",
              name: "guarantee",
              component: () =>
                import(
                  "@/view/pages/bank-guarantee/guarantee/Bank_Guarantee.vue"
                )
            }
          ]
        },
        // router to operational history -> officials history
        {
          path: "/field-activities",
          name: "field-activities",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/view/pages/field-activities/Field_Activities.vue"
                )
            }
          ]
        },
        // router to operational history -> officials history
        {
          path: "/operational-history",
          name: "operational-history",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/view/pages/operational-history/Operational_History.vue"
                )
            }
          ]
        },
        // router to master users
        {
          path: "/master/users",
          name: "master-users",
          component: () => import("@/view/pages/Vuetify.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import("@/view/pages/master/Master_Users.vue")
            }
          ]
        },
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/Vuetify.vue"),
      children: [
        // router to login
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        // router to register
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
